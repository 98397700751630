import React from 'react'

import styles from './BlogBlock.module.css';
import BlockHeader from '../block-header/BlockHeader';
import {blogArticles} from './mockData'

const blogData = {
  title: 'Blog',
  description: ''
}

const BlogBlock = () => {
    const getHTMLTitle = (title) => {
        return {__html: title};
    }

  return (
    <div className={styles.blog}>
      <div>
        <BlockHeader header={blogData}/>
      </div>
      <div className={styles.content}>
          {blogArticles.map((art, index) => {
              if (art?.empty) {
                  return (<div key={index} className={styles.article}></div>)
              }

              if (typeof art?.isWholeBlockClickable === 'boolean' && !art?.isWholeBlockClickable) {
                  return (
                      <div className={styles.article} key={art.title}>
                          <div className="content">
                              <img src={art.img} className={styles.img} />
                              <div className={styles.title} dangerouslySetInnerHTML={getHTMLTitle(art.title)}></div>
                              <div className={styles.intro}>{art.intro}</div>
                          </div>d
                          <div className="footer">
                              <a href={`${art.url}`} target='_blank'>
                                  <div className={styles.readMore}>Read more</div>
                              </a>
                          </div>
                      </div>
                  )

              }

              return (
                  <div className={styles.article} key={art.title}>
                      <div className="content">
                          <a href={`${art.url}`} target="_blank">
                              <img src={art.img} className={styles.img} />
                              <div className={styles.title}>{art.title}</div>
                            </a>
                          <div className={styles.intro}>{art.intro}</div>
                      </div>
                      <div className="footer">
                          <a href={`${art.url}`} target='_blank'>
                              <div className={styles.readMore}>Read more</div>
                          </a>
                      </div>
                  </div>
              )
          })}
      </div>
    </div>
  )
}

export default BlogBlock