import TechprosVector from './../../images/blog/TechprosVector.jpeg'
import LiferayVector from './../../images/blog/LiferayVector.jpeg';
import VectorEtain from './../../images/blog/VectorEtain.jpg';
import YDAVector from './../../images/blog/YDAVector.jpg';

export const blogArticles = [
  {
    img: TechprosVector,
    title: 'Joining forces to become stronger: Introducing the Techpros & Vector partnership',
    intro: 'Trust is the foundation upon which the confidence in project deliverables and overall performance is built. It is a significant milestone that Techpros and Vector have proudly attained working hand in hand. \n \n \n',
    url: 'https://www.linkedin.com/pulse/joining-forces-become-stronger-introducing-techpros-vector'
  },
  {
    img: LiferayVector,
    title: 'In 2024, Vector Team takes a significant step forward by achieving the Enterprise Liferay partnership status in collaboration with Liferay.',
    intro: 'This partnership marks a commitment to expanding knowledge in development, enhancing customer relations, and undertaking projects from inception to delivery, aiming to provide even greater value to existing clients and partners.',
    url: 'https://www.linkedin.com/feed/update/urn:li:activity:7159112181005860864/'
  },
  {
    img: YDAVector,
    title: "YDA — an Open-Source Java AI Assistant",
    intro: "Perfectly suited for Spring and Liferay-powered projects, YDA is a comprehensive, customizable, and scalable tool designed to seamlessly integrate AI into your business operations.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7236787747942727680"
  },
  {
    img: VectorEtain,
    title: '<a href="https://www.linkedin.com/company/etainno/" target="_blank">Etain</a> and <a href="https://www.linkedin.com/company/love-vector/" target="_blank">Vector</a> have signed a partnership agreement',
    intro: "Vector shall provide software development outsourcing services by building a remote, dedicated team to meet Etain's needs.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7236023597163573248",
    isWholeBlockClickable: false
  },
  {
    empty: true
  },
  {
    empty: true
  },
]