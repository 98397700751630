import React from "react"

import Layout from "../components/layout";
import SEO from "../components/seo";
import BlogBlock from "../components/blog/BlogBlock";

const BlogPage = () => (
    <Layout>
        <SEO title="Blog Page" />
        <div className='container'>
            <BlogBlock/>
        </div>
    </Layout>
)

export default BlogPage;
